
import dayjs from "dayjs";
import "dayjs/locale/ja";
import {
  checkNotification,
  fetchNotifications,
  getNotificationStream,
  getAllNotificationsLength
} from "@/api/notification";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MNavBar from "@/components/MNavBar.vue";
import MHeaderPanel from "@/components/MHeaderPanel.vue";
import MnNotificationItem from "@/components/notification/MnNotificationItem.vue";
import MSMessagePanel from "@/components/student/MSMessagePanel.vue";
import MTableConditionItem from "@/components/MTableConditionItem.vue";
import {
  Notification,
  NotificationType,
  notificationTypes,
  notificationCollectionKey
} from "@/entities/notification";
import store from "@/store";
import firebase from "firebase/app";
import { Options, Vue } from "vue-class-component";
import { Student } from "@/entities/student";
import { saveErrorLog } from "@/api/error";
import { State, Getter } from "@/store/helper";
import { putSubmission } from "@/api/submission";
import { sendTextMessage } from "@/api/message";
import { createMessageReservation } from "@/api/message_reservation";
import { MessageToType } from "@/entities/message_reservation";
import { MessageUserType } from "@/entities/message";
import { getSchoolDetail } from "@/api/school";
import {
  setNotificationForSubmissionChecked,
  convertAndSortNotification
} from "@/api/notification";
import { getEmail } from "@/api/auth";

@Options({
  components: {
    MButton,
    MIcon,
    MNavBar,
    MHeaderPanel,
    MnNotificationItem,
    MSMessagePanel,
    MTableConditionItem
  },
  watch: {
    selectedMessageTypes() {
      const selectedMessageTypesOfLocalStorage = localStorage[this.email]
        ? {
            ...JSON.parse(localStorage[this.email]),
            selectedMessageTypes: this.selectedMessageTypes
          }
        : {
            selectedMessageTypes: this.selectedMessageTypes
          };
      localStorage[this.email] = JSON.stringify(
        selectedMessageTypesOfLocalStorage
      );
    },
    selectedLearningRoomMessageTypes() {
      const selectedLearningRoomMessageTypesOfLocalStorage = localStorage[
        this.email
      ]
        ? {
            ...JSON.parse(localStorage[this.email]),
            selectedLearningRoomMessageTypes: this
              .selectedLearningRoomMessageTypes
          }
        : {
            selectedLearningRoomMessageTypes: this
              .selectedLearningRoomMessageTypes
          };
      localStorage[this.email] = JSON.stringify(
        selectedLearningRoomMessageTypesOfLocalStorage
      );
    },
    selectedTimeKeeperMessageTypes() {
      const selectedTimeKeeperMessageTypesOfLocalStorage = localStorage[
        this.email
      ]
        ? {
            ...JSON.parse(localStorage[this.email]),
            selectedTimeKeeperMessageTypes: this.selectedTimeKeeperMessageTypes
          }
        : {
            selectedTimeKeeperMessageTypes: this.selectedTimeKeeperMessageTypes
          };
      localStorage[this.email] = JSON.stringify(
        selectedTimeKeeperMessageTypesOfLocalStorage
      );
    },
    selectedSubmissionMessageTypes() {
      const selectedSubmissionMessageTypesOfLocalStorage = localStorage[
        this.email
      ]
        ? {
            ...JSON.parse(localStorage[this.email]),
            selectedSubmissionMessageTypes: this.selectedSubmissionMessageTypes
          }
        : {
            selectedSubmissionMessageTypes: this.selectedSubmissionMessageTypes
          };
      localStorage[this.email] = JSON.stringify(
        selectedSubmissionMessageTypesOfLocalStorage
      );
    },
    selectedNotificationTypes() {
      const selectedNotificationTypesOfLocalStorage = localStorage[this.email]
        ? {
            ...JSON.parse(localStorage[this.email]),
            selectedNotificationTypes: this.selectedNotificationTypes
          }
        : {
            selectedNotificationTypes: this.selectedNotificationTypes
          };
      localStorage[this.email] = JSON.stringify(
        selectedNotificationTypesOfLocalStorage
      );
      this.changeNotificationType(this.selectedNotificationTypes);
    },
    userRef() {
      if (this.notifications.length === 0) {
        this.getNotifications();
      }
    },
    selectedNotificationId() {
      const selectedItem = this.notifications.filter(
        (n: Notification) => n.ref.id === this.selectedNotificationId
      ) as Notification[];
      if (selectedItem.length === 0) {
        return;
      }

      if (
        selectedItem[0].data.type === "get_message" ||
        selectedItem[0].data.type === "get_image_message" ||
        selectedItem[0].data.type === "enter_room" ||
        selectedItem[0].data.type === "leave_room" ||
        selectedItem[0].data.type === "start_timer" ||
        selectedItem[0].data.type === "end_timer" ||
        selectedItem[0].data.type === "post_reflection" ||
        selectedItem[0].data.type === "post_reflection_of_timer" ||
        selectedItem[0].data.type === "post_submission" ||
        selectedItem[0].data.type === "obtain_badge"
      ) {
        store.dispatch(
          "messagePanel/changeMessageStream",
          selectedItem[0].data.user
        );
      }
    }
  }
})
export default class Notifications extends Vue {
  notificationStream: (() => void) | null = null;
  notifications: Notification[] = [];
  selectedNotificationId = "";
  limit = 20;
  finished = false;
  load = false;
  allNotificationsLength = 0;
  execTime = 0;
  messageTypes: NotificationType[] = [
    "get_message",
    "get_image_message",
    "obtain_badge"
  ];
  timeKeeperMessageTypes: NotificationType[] = [
    "start_timer",
    "end_timer",
    "post_reflection_of_timer"
  ];
  learningRoomMessageTypes: NotificationType[] = [
    "enter_room",
    "leave_room",
    "post_reflection"
  ];
  submissionMessageTypes: NotificationType[] = ["post_submission"];
  allNotificationTypes: NotificationType[] = notificationTypes;
  notificationTypes: NotificationType[] = this.allNotificationTypes;
  hasNotifications = false;
  selectedMessageTypes: NotificationType[] = [];
  selectedLearningRoomMessageTypes: NotificationType[] = [];
  selectedTimeKeeperMessageTypes: NotificationType[] = [];
  selectedSubmissionMessageTypes: NotificationType[] = [];
  email = "";

  @State("messageUserRef", "messagePanel")
  messageUserRef!: firebase.firestore.DocumentReference | null;
  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isTutorOfSchool") isTutorOfSchool!: boolean;

  get userRef(): firebase.firestore.DocumentReference | null {
    if (!store.state.role) {
      return null;
    }
    return store.state.role.data.ref;
  }

  get messageStudent(): Student | null {
    if (!this.messageUserRef) {
      return null;
    }

    if (this.messageUserRef.parent.id === "students") {
      const matchStudents = store.state.students.filter(
        student => student.ref.id === this.messageUserRef!.id
      );
      if (matchStudents.length > 0) {
        return matchStudents[0];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  get inRecess(): boolean {
    if (!this.messageStudent) {
      return false;
    }

    const matchStudents = store.state.students.filter(
      student => student.ref.id === this.messageStudent!.ref.id
    );
    if (matchStudents.length === 0) {
      return false;
    }
    const student = matchStudents[0];

    if (student.data.recessTime && student.data.recessTime > 0) {
      return true;
    } else {
      return false;
    }
  }

  get notificationIds(): string[] {
    return this.notifications.map(n => n.ref.id);
  }

  changeNotificationType(notificationTypes: NotificationType[]) {
    if (!this.hasNotifications) return;
    this.notificationTypes = notificationTypes;
    this.finished = false;
    this.getNotifications();
  }

  selectAllFilter() {
    this.selectAllMessageTypes();
    this.selectAllLearningRoomMessageTypes();
    this.selectAllTimeKeeperMessageTypes();
    this.selectAllSubmissionMessageTypes();
  }

  get selectedNotificationTypes() {
    return [
      ...this.selectedMessageTypes,
      ...this.selectedLearningRoomMessageTypes,
      ...this.selectedTimeKeeperMessageTypes,
      ...this.selectedSubmissionMessageTypes,
      "other",
      "announce"
    ];
  }

  selectAllMessageTypes() {
    this.selectedMessageTypes = this.messageTypes;
  }
  selectAllLearningRoomMessageTypes() {
    this.selectedLearningRoomMessageTypes = this.learningRoomMessageTypes;
  }
  selectAllTimeKeeperMessageTypes() {
    this.selectedTimeKeeperMessageTypes = this.timeKeeperMessageTypes;
  }
  selectAllSubmissionMessageTypes() {
    this.selectedSubmissionMessageTypes = this.submissionMessageTypes;
  }

  deselectAllMessageTypes() {
    this.selectedMessageTypes = [];
  }
  deselectAllLearningRoomMessageTypes() {
    this.selectedLearningRoomMessageTypes = [];
  }
  deselectAllTimeKeeperMessageTypes() {
    this.selectedTimeKeeperMessageTypes = [];
  }
  deselectAllSubmissionMessageTypes() {
    this.selectedSubmissionMessageTypes = [];
  }

  created() {
    this.email = getEmail();
    if (
      !localStorage[this.email] ||
      !JSON.parse(localStorage[this.email]).selectedNotificationTypes
    ) {
      // localStorageに通知メッセージのフィルタ条件が保存されていなかった場合
      this.selectAllFilter();
    } else {
      // localStorageに通知メッセージのフィルタ条件が保存されていた場合
      this.selectedMessageTypes = JSON.parse(
        localStorage[this.email]
      ).selectedMessageTypes;
      this.selectedLearningRoomMessageTypes = JSON.parse(
        localStorage[this.email]
      ).selectedLearningRoomMessageTypes;
      this.selectedTimeKeeperMessageTypes = JSON.parse(
        localStorage[this.email]
      ).selectedTimeKeeperMessageTypes;
      this.selectedSubmissionMessageTypes = JSON.parse(
        localStorage[this.email]
      ).selectedSubmissionMessageTypes;
      this.notificationTypes = [
        ...this.selectedMessageTypes,
        ...this.selectedLearningRoomMessageTypes,
        ...this.selectedTimeKeeperMessageTypes,
        ...this.selectedSubmissionMessageTypes
      ];
    }
    if (this.userRef) {
      this.getNotifications();
    }
  }

  async confirm(notification: Notification) {
    if (!notification.data.submissionRef) {
      return;
    }
    if (this.load) {
      return;
    }
    this.load = true;

    let submissionData: firebase.firestore.DocumentData | undefined;
    const now = dayjs();
    store.commit("START_LOADING", "ステータス更新中...");
    try {
      const submission = await notification.data.submissionRef.get();
      submissionData = submission.data();
      if (!submissionData) {
        throw new Error("submission not found");
      }
      // すでに確認済みの場合は既読にだけする
      if (submissionData.status === "fulfilled") {
        await setNotificationForSubmissionChecked(
          notification.data.submissionRef
        );
        this.load = false;
        store.commit("END_LOADING");
        this.$router.go(0);
        return;
      }

      await putSubmission(notification.data.submissionRef, {
        checkedAt: now.unix(),
        status: "fulfilled"
      });
    } catch (e) {
      alert(`提出物の更新中にエラーが発生しました。`);
      this.load = false;
      store.commit("END_LOADING");
      return;
    }

    try {
      // 塾名の取得
      const [school] = await getSchoolDetail(
        false,
        submissionData.submissionMasterRef?.parent?.parent?.id ?? ""
      );
      if (!school) throw new Error("school not found");
      const schoolName = school.data.name;
      // 差出人表記の取得
      let fromName = "";
      if (this.isTutorOfSchool) {
        fromName = store.state.tutor?.main.data.name ?? "";
      } else if (this.isAdmin) {
        fromName = store.state.admin?.name ?? "";
      } else {
        throw new Error("user not found");
      }
      const messageFrom = {
        type: "tutor" as MessageUserType,
        userId: store.state.role?.ref.id ?? "",
        name: fromName
      };

      const submissionMaster = await submissionData.submissionMasterRef.get();
      const submissionMasterData = submissionMaster.data();
      const messageText = `🙆‍♂️「${submissionMasterData.name}」を確認しました。おつかれさま😄`;
      const studentRef = notification.data.user;

      if (now.hour() >= 7 && now.hour() <= 21) {
        // 7:00 ~ 21:59 の間は即座に生徒にメッセージを送信する
        await sendTextMessage(studentRef, schoolName, messageFrom, messageText);
        alert(`提出物を確認済にし、生徒にメッセージを送信しました。`);
      } else {
        // 夜間早朝は次の 7:00 に送信されるメッセージを予約する
        const messageTo = {
          type: "students" as MessageToType,
          students: [studentRef]
        };
        const reservationTime = dayjs()
          .add(now.hour() > 21 ? 1 : 0, "day")
          .hour(7)
          .minute(0)
          .second(0)
          .unix();
        await createMessageReservation(
          messageFrom,
          messageTo,
          "text",
          messageText,
          reservationTime,
          school.ref.id,
          schoolName
        );
        alert(
          `提出物を確認済にしました。生徒へのメッセージは次の朝 7 時に送信されます。`
        );
      }

      await setNotificationForSubmissionChecked(
        notification.data.submissionRef
      );
      store.commit("END_LOADING");
      /*
        複数の通知メッセージを一度に除外すべき場合があるので
        this.check ではなく再読み込みにする
        */
      this.$router.go(0);
    } catch (e) {
      store.commit("END_LOADING");
      alert(`メッセージ送信中にエラーが発生しました。`);
    }

    this.load = false;
  }

  async check(id: string) {
    if (!this.userRef) {
      return;
    }

    const checkedNotification = this.notifications.filter(
      n => n.ref.id === id
    )[0];
    this.notifications = this.notifications.filter(n => n.ref.id !== id);
    if (this.selectedNotificationId === id) {
      if (this.notifications.length > 0) {
        this.selectedNotificationId = this.notifications[0].ref.id;
      } else {
        this.selectedNotificationId = "";
      }
    }

    try {
      await checkNotification(
        this.userRef.collection(notificationCollectionKey).doc(id)
      );
    } catch (e) {
      alert(
        `通知の既読状態を保存するのに失敗しました。時間を空けてから再度試してみて下さい\n\n${e}`
      );
      const recoverNotifications = [...this.notifications, checkedNotification];
      recoverNotifications.sort((a, b) => a.data.timestamp - b.data.timestamp);
      this.notifications = recoverNotifications;
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to check notification"
      );
    }
    this.getAllNotifications();
  }

  select(id: string) {
    this.selectedNotificationId = id;
    if (window.innerWidth < 1024) {
      const selectedItem = this.notifications.filter(
        (n: Notification) => n.ref.id === id
      ) as Notification[];
      if (selectedItem.length === 0) {
        return;
      }
      const student = selectedItem[0].data.user;
      if (student.parent.id !== "students") {
        return;
      }
      const classroomRef = student.parent.parent;
      if (!classroomRef) {
        return;
      }
      const schoolRef = classroomRef.parent.parent;
      if (!schoolRef) {
        return;
      }
      this.$router.push(
        `/student/${schoolRef.id}/${classroomRef.id}/${student.id}/profile`
      );
    }
  }

  async checkAllNotification() {
    const res = confirm(
      `現在届いている通知メッセージ全てを既読にしますがよろしいですか？\n※表示されていない通知メッセージも含めて全てが既読となります。`
    );
    if (!res) {
      return;
    }
    if (!this.userRef) {
      return;
    }
    this.execTime = dayjs()
      .locale("ja")
      .unix();
    try {
      const updateNotification = firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("update_notification");

      updateNotification({
        schoolId: this.userRef.parent.parent?.id ?? "",
        tutorId: this.userRef.id,
        execTime: this.execTime
      });
    } catch (e) {
      alert(`全ての通知を既読にできませんでした\n\n${e.message}`);
      saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to check all notifications"
      );
    } finally {
      this.notifications = [];
      this.allNotificationsLength = 0;
    }
  }

  async getNotifications() {
    this.hasNotifications = false;
    if (!this.userRef) {
      return;
    }

    this.notifications = [];
    const stream = getNotificationStream(
      this.userRef,
      1,
      this.execTime
    ).onSnapshot(snapshot => {
      snapshot.docChanges().forEach(async change => {
        const changeNotification = await convertAndSortNotification(
          change.doc.data(),
          change.doc.ref,
          this.isAdmin
        );
        if (
          changeNotification !== null &&
          change.type === "added" &&
          !this.notificationIds.includes(changeNotification.ref.id) &&
          this.notificationTypes.includes(change.doc.data().type)
        ) {
          this.notifications.push(changeNotification);
          this.notifications.sort(
            (a, b) => b.data.timestamp - a.data.timestamp
          );

          if (!this.selectedNotificationId) {
            this.selectedNotificationId = changeNotification.ref.id;
          }
        }
      });
    });
    this.notificationStream = stream;
    try {
      const notifications = await fetchNotifications(
        this.notificationTypes,
        this.userRef,
        this.limit,
        this.isAdmin
      );
      notifications.forEach(item => {
        if (!this.notificationIds.includes(item.ref.id)) {
          this.notifications.push(item);
        }
      });
      this.notifications.sort((a, b) => b.data.timestamp - a.data.timestamp);
      if (notifications.length < this.limit) {
        this.finished = true;
      }
    } catch (e) {
      alert(`通知の取得に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to get notification"
      );
    }

    this.getAllNotifications();
    this.hasNotifications = true;
  }

  async getMoreNotifications() {
    if (
      this.finished ||
      this.load ||
      this.notifications.length === 0 ||
      !this.userRef
    ) {
      return;
    }
    const lastNotification = this.notifications[this.notifications.length - 1];

    this.load = true;
    try {
      const notifications = await fetchNotifications(
        this.notificationTypes,
        this.userRef,
        this.limit,
        this.isAdmin,
        lastNotification.ref
      );
      notifications.forEach(item => {
        if (!this.notificationIds.includes(item.ref.id)) {
          this.notifications.push(item);
        }
      });
      this.notifications.sort((a, b) => b.data.timestamp - a.data.timestamp);
      if (notifications.length < this.limit) {
        this.finished = true;
      }
    } catch (e) {
      alert(`通知の取得に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to get notification"
      );
    }
    this.load = false;
  }

  async getAllNotifications() {
    if (!this.userRef) {
      return;
    }
    try {
      this.allNotificationsLength = await getAllNotificationsLength(
        this.userRef,
        this.execTime,
        this.isAdmin
      );
    } catch (e) {
      alert(`通知メッセージの未読件数の取得に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to get notification length"
      );
    }
  }

  convertToLogicalName(type: string): string {
    switch (type) {
      case "get_message":
        return "メッセージ";
      case "get_image_message":
        return "画像";
      case "enter_room":
        return "入室（目標）";
      case "leave_room":
        return "退室";
      case "start_timer":
        return "開始（目標）";
      case "end_timer":
        return "終了";
      case "post_reflection":
        return "振り返り";
      case "post_reflection_of_timer":
        return "振り返り";
      case "announce":
        return "アナウンス";
      case "post_submission":
        return "提出報告";
      case "obtain_badge":
        return "バッジ獲得";
      case "other":
        return "その他";
      default:
        return "存在しない条件です";
    }
  }
}
